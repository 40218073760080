import "../styles/Contact.css";
import "font-awesome/css/font-awesome.min.css";

const Contact = () => {
	return (
		<div className="wrapper-contact-me">
			<div className="wrapper-link">
				<a
					className="links"
					href="https://github.com/theyashshahs"
					target="blank"
				>
					<i className="fa fa-github"> </i>
				</a>
				<a
					className="links"
					href="https://twitter.com/theyashshahs"
					target="blank"
				>
					<i className="fa fa-twitter"> </i>
				</a>
				<a
					className="links"
					href="https://linkedin.com/in/theyashshahs"
					target="blank"
				>
					<i className="fa fa-linkedin"> </i>
				</a>
				<a
					className="links"
					href="https://medium.com/@theyashshahs"
					target="_blank"
					rel="noreferrer"
				>
					<i className="fa fa-medium"> </i>
				</a>
				<a
					className="links"
					href="mailto:yashshah20000@gmail.com"
					target="blank"
				>
					<i className="fa fa-envelope"> </i>
				</a>
			</div>
		</div>
	);
};

export default Contact;
