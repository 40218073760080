import "../styles/Logo.css";

const Logo = (props) => {
	return (
		<div className={props.class}>
			<span className="logo-text">⧖</span>
		</div>
	);
};

export default Logo;
