import "../styles/Skills.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Fade } from "@mui/material";

const CustomToolTip = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		arrow
		classes={{ popper: className }}
		TransitionComponent={Fade}
		TransitionProps={{ timeout: 300 }}
	/>
))(() => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: "#007bff",
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: "#007bff",
		fontSize: "12px",
		fontWeight: "bold",
		padding: "8px",
		fontFamily: "Inter, sans-serif",
	},
}));

const SkillCard = (props) => {
	return (
		<div className="skill-item">
			<CustomToolTip title={props.name} arrow>
				<img src={process.env.PUBLIC_URL + props.image} alt="skill" />
			</CustomToolTip>
		</div>
	);
};

export default SkillCard;
