const skillsList = [
	{
		name: "Python",
		image: "/skills/python.svg",
	},
	{
		name: "C++",
		image: "/skills/c_plus.svg",
	},
	{
		name: "C",
		image: "/skills/c.svg",
	},
	{
		name: "Go",
		image: "/skills/go.svg",
	},
	{
		name: "CSS",
		image: "/skills/css3.svg",
	},
	{
		name: "JavaScript",
		image: "/skills/javascript.svg",
	},
	{
		name: "React",
		image: "/skills/react.svg",
	},
	{
		name: "Django",
		image: "/skills/django.svg",
	},
	{
		name: "Firebase",
		image: "/skills/firebase.svg",
	},
	{
		name: "PostgreSQL",
		image: "/skills/postgresql.svg",
	},
	{
		name: "Redis",
		image: "/skills/redis.svg",
	},
	{
		name: "Git",
		image: "/skills/git.svg",
	},
	{
		name: "GitHub",
		image: "/skills/github.svg",
	},
	{
		name: "GitLab",
		image: "/skills/gitlab.svg",
	},
	{
		name: "Jira",
		image: "/skills/jira.svg",
	},
	{
		name: "Docker",
		image: "/skills/docker.svg",
	},
	{
		name: "AWS",
		image: "/skills/amazon-aws.svg",
	},
	{
		name: "S3",
		image: "/skills/s3.svg",
	},
];

export default skillsList;
