import "../styles/Header.css";
import Logo from "./Logo";

const Header = () => {
	return (
		<div className="header">
			<nav className="navbar">
				<div className="nav-left">
					<Logo class="logo-small" />
					<a href="/">
						<span>Yash Shah</span>
					</a>
				</div>
				<div className="nav-right">
					{/* <ul> Home </ul>
					<ul> Skills </ul>
					<ul> Projects </ul> */}
					<ul>
						<a
							href={process.env.PUBLIC_URL + "/Resume.pdf"}
							target="_blank"
							rel="noopener noreferrer"
						>
							<span className="resume">Resume</span>
						</a>
					</ul>
				</div>
			</nav>
		</div>
	);
};

export default Header;
