import "../styles/Skills.css";
import SkillCard from "./SkillCard";
import skillsList from "../utils/skills";

const Skills = () => {
	let cardList = [];
	skillsList.forEach((skill, index) => {
		cardList.push(
			<SkillCard name={skill.name} image={skill.image} key={index} />
		);
	});

	return (
		<section className="wrapper-skills">
			<div className="skills">
				<span>Skills</span>
			</div>
			<div className="skills-container">{cardList}</div>
		</section>
	);
};

export default Skills;
