import "../styles/Footer.css";
import Contact from "./Contact";
const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer-wrapper">
				<p className="footer-left">
					Designed and Developed by Yash Shah
				</p>
				<div className="footer-right">
					<Contact />
				</div>
			</div>
		</footer>
	);
};

export default Footer;
