import "../styles/Home.css";
import Header from "./Header";
import profile_pic from "../assets/profile_pictue.webp";
import Skills from "./Skills";
import Footer from "./Footer";

const Home = () => {
	return (
		<div className="home">
			<Header />
			<div className="heading">
				<div className="heading-left">
					<img src={profile_pic} alt="profile" />
				</div>
				<div className="heading-right">
					<span className="hi">Hi</span>
					<span className="wave" role="img" aria-label="wave">
						👋
					</span>
					<div className="title">
						I am <span className="name">Yash Shah</span>
						<br />
						<span className="subtitle">Full-Stack Developer</span>
					</div>
				</div>
			</div>
			<div className="about">
				<p>
					I am a final year undergraduate at &nbsp;
					<a
						href="http://iiitvadodara.ac.in/"
						target="_blank"
						rel="noreferrer"
					>
						<span className="highlight">
							Indian Institute of Information Technology Vadodara
						</span>
					</a>
					.
				</p>
				<p>
					I am a <span className="unique">Full-Stack Developer</span>,
					currently building&nbsp;
					<a
						href="https://gosocial.io/"
						target="_blank"
						rel="noreferrer"
					>
						<span className="highlight">GoSocial</span>
					</a>
					&nbsp; at <span className="highlight">Hapramp Studio</span>.
					I have experience in building scalable, robust and
					production ready backend applications. Also, I have
					experience with deployment piplines and cloud
					infrastructure.
				</p>
			</div>

			<hr className="line" />
			<Skills />

			<hr className="line" />

			<Footer />
		</div>
	);
};

export default Home;
